import { useContext } from "react";
import noteContext from "../context/Notes/noteContext";
import Alert from "./Alert";
import Notes from "./Notes";

const Home = () => {
  /* ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  // VARIBLES

  const context = useContext(noteContext);
  const { alert } = context;
  /* ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  // RETURN

  return (
    <>
      <div
        className=" relative sticky-top "
        style={{
          minHeight: "2vh",
          maxHeight: "2.1vh",
          // zIndex:"99",
          paddingTop: "4rem",
          marginTop: "4rem",
        }}
      >
        {alert.show && <Alert type={alert.type} message={alert.message} />}
      </div>
      <Notes />
    </>
  );
};

/* ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

// EXPORT

export default Home;
