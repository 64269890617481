import { useState } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "./userContext";

const UserState = (props) => {
  /* ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  // VARIABLES

  const host = process.env.REACT_APP_HOST;
  console.log(host);
  const navigate = useNavigate();
  const [alert, setAlert] = useState({
    show: false,
    type: "",
    message: "",
  });

  /* ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  // METHODS

  const toggleAlert = (recievedmessage, recievedtype) => {
    setAlert({
      show: true,
      message: recievedmessage,
      type: recievedtype,
    });
    setTimeout(() => {
      setAlert({
        message: "",
        type: "",
        show: false,
      });
    }, 4000);
  };

  /* ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  // SIGNUP

  const signUp = async (userData) => {
    const response = await fetch(`${host}/api/auth/createuser`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });
    const json = await response.json();
    if (json.success) {
      // localStorage.setItem('token'.json.authToken)
      navigate("/login");
      toggleAlert(json.message, "success");
    } else {
      toggleAlert(json.message, "danger");
    }
  };

  /* ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  // LOGIN

  const logIn = async (userData) => {
    const response = await fetch(`${host}/api/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });
    const json = await response.json();
    if (json.success) {
      // setAuthToken(json.authToken);
      localStorage.setItem("token", json.authToken);
      toggleAlert(json.message, "success");
      navigate("/");
    } else {
      toggleAlert(json.message, "danger");
    }
  };

  /* ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

  // RETURN

  return (
    <UserContext.Provider value={{ signUp, logIn, alert }}>
      {props.children}
    </UserContext.Provider>
  );
};

/* ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

// EXPORT

export default UserState;
